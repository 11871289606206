<!--参数管理页面-->
<template>
  <page-container title="参数管理" :breadcrumb="breadcrumb">
    <div class="dept-container">
      <div class="box-container" style="margin-top: 24px; width: 320px; padding-bottom: 24px;">
        <div class="box-container-inner" style="height: calc(100vh - 200px); overflow: auto;">
          <!-- <div class="tree-top">
            <a-button type="primary" @click="addNewItem">新增</a-button>
          </div> -->
          <a-tree
            :selected-keys.sync="selectedKeys"
            :replace-fields="{children:'children', title:'devparamcfgname', key:'devparamcfgid'}"
            :tree-data="paramsConfigTree"
            :expandedKeys.sync="expandedKeys"
            :load-data="onLoadData"
            @select="selectItem"
          >
            <span slot="customTreeNode" slot-scope="node">
              <span>{{node.dataRef.devparamcfgname}}</span>
              <a-tooltip v-if="node.dataRef.devicemoduletype!='1'||node.dataRef.devicemoduletype=='0'">
                <template slot="title">新增子节点</template>
                <a-icon type="plus" @click.stop="addChildQuick(node)" style="margin-left: 7px;" />
              </a-tooltip>
              <a-tooltip v-if="node.dataRef.level>1">
                <template slot="title">删除</template>
                <a-icon type="delete" @click.stop="deleteQuick(node)" style="margin-left: 7px;" />
              </a-tooltip>
            </span>
          </a-tree>
        </div>
      </div>
      <div class="box-container" style="margin-top: 24px; flex: 1; padding-bottom: 24px; padding-left: 0;">
        <div class="box-container-inner" style="height: calc(100vh - 200px); overflow-y: auto;">
          <a-form-model ref="paramsConfigForm" :model="formDatas" :rules="formRules" :label-col="{span:8}" :wrapper-col="{span:14}">
            <a-form-model-item :wrapper-col="{ span: 22, offset: 0 }" style="width: 100%; margin-bottom: 20px; overflow: hidden;">
              <a-button v-if="formType=='edit'&&selectedItem&&selectedItem.devicemoduletype=='0'" size="large" @click="addChild" style="margin-left: 10px;float: right;"><a-icon type="plus"></a-icon>新增子节点</a-button>
              <a-button v-if="formType=='edit'&&selectedItem&&selectedItem.level>1" size="large" type="danger" @click="deleteConfirm" style="margin-left: 10px; float: right;">删除</a-button>
              <a-button style="float: right;" v-if="selectedItem&&(selectedItem.level>1||formType=='add')" size="large" type="primary" @click="save">保存</a-button>
            </a-form-model-item>
            <a-form-model-item label="参数配置英文名" prop="devparamcfgnum">
              <a-input v-model.trim="formDatas.devparamcfgnum" />
            </a-form-model-item>
            <a-form-model-item label="参数配置名称" prop="devparamcfgname">
              <a-input v-model.trim="formDatas.devparamcfgname" />
            </a-form-model-item>
            <a-form-model-item label="参数模式" prop="devparammodel">
              <a-select v-model="formDatas.devparammodel">
<!--                disabled-->
                <a-select-option value="">请选择</a-select-option>
                <a-select-option value="2">类型</a-select-option>
                <a-select-option value="3">方法</a-select-option>
                <a-select-option value="1">参数</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item v-if="formDatas.devparammodel=='1'" label="数值类型" prop="devicemoduletype">
              <a-select v-model="formDatas.devicemoduletype">
                <a-select-option value="0">object</a-select-option>
                <a-select-option value="1">string</a-select-option>
                <a-select-option value="2">int</a-select-option>
                <a-select-option value="3">float</a-select-option>
                <a-select-option value="4">double</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item v-if="formDatas.devparammodel=='3'" label="url" prop="url">
              <a-input v-model.trim="formDatas.url" />
            </a-form-model-item>
            <a-form-model-item label="描述" prop="devparamdesc">
              <a-input v-model.trim="formDatas.devparamdesc" />
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
  </page-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getDeviceparamChildLisByParentId, deleteDeviceparam, addDeviceparam, modifyDeviceparam } from 'A/ai'
import { getItemFromArrayByKey } from 'U'
import store from '@/store'

export default {
  name: 'paramsList',
  data() {
    return {
      breadcrumb: [
        {
          name: '网络管理',
          path: ''
        },
        {
          name: '监控硬终端',
          path: ''
        },
        {
          name: '参数管理',
          path: ''
        },
      ],
      paramsConfigTree: [],
      expandedKeys: [],
      selectedKeys: [],
      selectedItem: null,
      formType: '',
      formDatas: {
        devparamcfgid: '',
        parentid: '',
        devparamcfgnum: '',
        devparamcfgname: '',
        devparammodel: '',
        devicemoduletype: '',
        url: '',
        devparamdesc: '',
      },
      formRules: {
        devparamcfgnum: [{required: true, message: '请输入参数配置英文名'}],
        devparamcfgname: [{required: true, message: '请输入参数配置名称'}],
        devparammodel: [{required: true, message: '请选择参数模式'}],
        devicemoduletype: [{required: true, message: '请选择数值类型'}],
        url: [{required: true, message: '请输入url'}],
      },
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    parentname() {
      let result = '';
      // debugger
      if(this.formDatas.parentid) {
        let parentNode = getItemFromArrayByKey(this.paramsConfigTree, 'devparamcfgid', this.formDatas.parentid);
        if(parentNode) {
          result = parentNode.devparamcfgname;
        }
      }
      return result;
    },
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTreeRoot();
    },
    getTreeRoot(parentid='') {
      let params = {
        parentid
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.paramsConfigTree = res.item.map(item => {
            item.isLeaf = item.childsize === 0;
            item.scopedSlots = {title: 'customTreeNode'}
            item.level = 1;
            return item;
          });
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    onLoadData(node) {
      return new Promise(resolve => {
        if (node.dataRef.children) {
          resolve();
          return;
        }
        let params = {
          parentid: node.dataRef.devparamcfgid
        };
        this.showLoading();
        getDeviceparamChildLisByParentId(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            node.dataRef.children = res.item.map(item => {
              item.isLeaf = item.childsize === 0;
              item.scopedSlots = {title: 'customTreeNode'}
              item.level = node.dataRef.level + 1;
              return item;
            });
            this.paramsConfigTree = [...this.paramsConfigTree];
            resolve();
          }
        }).catch(err => {
          this.hideLoading();
        })
      });
    },
    addNewItem() {
      this.selectedItem = null;
      this.setFormData('addNew');
    },
    addChildQuick(node) {
      this.selectedItem = node.dataRef;
      this.addChild();
    },
    deleteQuick(node) {
      this.selectedItem = node.dataRef;
      this.deleteConfirm();
    },
    addChild() {
      if(this.selectedItem) {
        this.setFormData('add');
      }else {
        this.$message.info('请选择一条数据');
      }
    },
    selectItem(item) {
      let devparamcfgid = item[0];
      if(devparamcfgid) {
        this.selectedItem = getItemFromArrayByKey(this.paramsConfigTree, 'devparamcfgid', devparamcfgid);
        for(let key in this.selectedItem) {
          if(typeof this.selectedItem[key] == 'number') {
            this.selectedItem[key] = this.selectedItem[key].toString()
            // if(this.selectedItem[key] == '0') {
            //   this.selectedItem[key] = '';
            // }
          }
        }
        this.setFormData('edit');
      }else {
        this.resetFormData();
        this.selectedItem = null;
      }
    },
    setFormData(type) {
      this.formType = type;
      this.resetFormData();
      if('edit' == this.formType) {
        if(this.selectedItem) {
          this.formDatas = {
            ...this.formDatas,
            ...this.selectedItem
          };
        }else {
          this.resetFormData();
        }
      }else {
        this.selectedKeys = [];
        // debugger
        if(this.selectedItem) {
          this.formDatas.parentid = this.selectedItem.devparamcfgid;
          if(this.selectedItem.level == '1') {
            this.formDatas.devparammodel = '3';
          }else {
            this.formDatas.devparammodel = '1';
          }
        }else {
          // 顶级节点
          this.formDatas.parentid = '';
        }
      }
    },
    resetFormData() {
      this.$refs.paramsConfigForm.resetFields();
      // this.formDatas.devparamcfgid = '';
    },
    save() {
      this.$refs.paramsConfigForm.validate(valid => {
        if(valid) {
          this.showLoading();
          let params = {
            ...this.formDatas
          };
          if(params.devparammodel!='1') {
            params.devicemoduletype = '';
          }
          if(params.devparammodel!='3') {
            params.url = '';
          }
          if(this.formType == 'edit') {
            modifyDeviceparam(params).then(res => {
              this.hideLoading();
              if(res && res.returncode == '0') {
                this.$message.success('操作成功');
                let dept = getItemFromArrayByKey(this.paramsConfigTree, 'devparamcfgid', this.formDatas.devparamcfgid);
                for(let key in dept) {
                  if(this.formDatas[key]) {
                    dept[key] = this.formDatas[key]
                  }
                }
              }else {
                this.$message.error(res.errormsg||'操作失败');
              }
            })
          }else {
            addDeviceparam(params).then(res => {
              this.hideLoading();
              if(res && res.returncode == '0') {
                this.$message.success('操作成功');
                let newNode = this.getDeptNode(res.devparamcfgid, {isLeaf: true, scopedSlots: {title: 'customTreeNode'}});
                if(this.formDatas.parentid) {
                  let parentNode = getItemFromArrayByKey(this.paramsConfigTree, 'devparamcfgid', this.formDatas.parentid);
                  parentNode.isLeaf = false;
                  if(!parentNode.children || !parentNode.children.length) {
                    parentNode.children = [];
                  }
                  newNode.level = parentNode.level+1;
                  parentNode.children.push(newNode)
                  this.paramsConfigTree = [...this.paramsConfigTree];
                  this.expandedKeys = [...this.expandedKeys, parentNode.devparamcfgid];
                }else {
                  newNode.level = 1;
                  this.paramsConfigTree.push(newNode)
                }
                this.selectedItem = newNode
                this.setFormData('edit');
              }else {
                this.$message.error(res.errormsg||'操作失败');
              }
            })
          }
        }
      })
    },
    getDeptNode(devparamcfgid, obj) {
      return {
        ...this.formDatas,
        devparamcfgid,
        ...obj
      }
    },
    deleteConfirm() {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete();
      }).catch(()=>{
      });
    },
    delete() {
      this.showLoading();
      let params = {
        devparamcfgid: this.selectedItem.devparamcfgid
      }
      deleteDeviceparam(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.$message.success('操作成功');
          if(this.selectedItem.parentid) {
            let parentNode = getItemFromArrayByKey(this.paramsConfigTree, 'devparamcfgid', this.selectedItem.parentid);
            parentNode.children = parentNode.children.filter(item => item.devparamcfgid!=this.selectedItem.devparamcfgid);
            this.paramsConfigTree = [...this.paramsConfigTree]
          }else {
            this.paramsConfigTree = this.paramsConfigTree.filter(item => item.devparamcfgid!=this.selectedItem.devparamcfgid);
          }
          this.resetFormData();
          this.selectedItem = null;
          this.selectedKeys = [];
        }else {
          this.$message.error(res.errormsg||'操作失败');
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.dept-container {
  display: flex;
}
</style>